<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="slide-x-transition"
  >
    <v-card v-if="!this.isLoading">
      <v-toolbar>
        <v-icon class="mr-10" title="Go Back" @click="dialog = false">
          mdi-arrow-left
        </v-icon>
        <v-toolbar-title>Offer Details</v-toolbar-title>
      </v-toolbar>

      <div class="pa-10">
        <v-row v-for="(value, key) in offer_data" :key="key">
          <v-col
            class="bordered font-weight-bold"
            style="max-width: 200px; min-width: 200px"
          >
            {{ key }}
          </v-col>
          <v-col class="bordered" style="min-width: 200px">
            {{ value }}
          </v-col>
        </v-row>
      </div>
      <div class="pa-5">
        <v-btn
          color="green darken-4"
          v-if="deal_summary_data.status !== 'awarded'"
          @click="$emit('edit', deal_summary_id, offer_id)"
        >
          Edit Offer
        </v-btn>
        <v-btn class="ml-5" color="red darken-4" @click="dialog = false">
          Close
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import API from '@/plugins/API';

export default {
  name: 'FullOfferDetailsDialog',

  data: () => ({
    deal_summary_id: null,
    offer_id: null,
    deal_summary_data: {},
    offer_data: {},
    dialog: false,
    isLoading: true,
  }),

  methods: {
    toggleViewOffer() {
      this.dialog = !this.dialog;
    },

    formatDate(date_string) {
      const date = new Date(date_string);
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      const monthName = months[date.getMonth()];

      const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      const year = date.getFullYear();
      const formattedDate = `${monthName} ${day}, ${year}`;

      return formattedDate;
    },

    async getData(deal_summary_id, offer_id) {
      try {
        const res = await API().get(
          `/api/user_deal_summary/view_offer?deal_summary_id=${deal_summary_id}&offer_id=${offer_id}`
        );
        console.log(res);
        this.deal_summary_data = res.data;

        this.deal_summary_id = deal_summary_id;
        this.offer_id = offer_id;

        this.deal_summary_data.summary_offers.forEach((el) => {
          let condition_string = '';

          el.appraisal
            ? (condition_string = condition_string.concat(' • Appraisal '))
            : '';

          el.insurance
            ? (condition_string = condition_string.concat(
                ' • Insurance with lender '
              ))
            : '';

          el.credit_check
            ? (condition_string = condition_string.concat(' • Credit check '))
            : '';

          el.income_verification
            ? (condition_string = condition_string.concat(
                ' • Income documents '
              ))
            : '';

          el.tax_verification
            ? (condition_string = condition_string.concat(
                ' • Personal income tax '
              ))
            : '';

          el.strata
            ? (condition_string = condition_string.concat(
                ' • Strata/estoppel/condo documents '
              ))
            : '';

          this.offer_data = {
            Comments: el.comments,
            Amount: parseInt(el.mortgage_amount).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
            Position: el.mortgage_position,
            Term: el.mortgage_term + '%',
            Interest_Rate: el.interest_rate + '%',
            Lender_Fee: el.lender_fee + '%',
            Status: el.status,
            Needed: condition_string,
            Created: this.formatDate(el.createdAt),
            Last_Update: this.formatDate(el.updatedAt),
          };
        });
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}
</style>
