<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h2>
            Welcome to my bids
            <v-progress-circular indeterminate color="primary" />
          </h2>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="8">
          <h2 class="mb-5">Welcome to my bids</h2>

          <p>
            Here you can see a list of deals which you have bid on previously.
            If a deal is active, you can go back into the deal and make more
            bids or adjust your existing ones.
          </p>

          <p>
            If your bid was chosen by the borrower, it will be shown as “Won By
            Me” status. If a bid by another company was chosen, it will show
            “Won By Others”.
          </p>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col class="bordered" xs="12" xl="8">
          <h4 class="float-right">
            My active bid total:
            <span class="ml-2" style="color: green">
              {{ formatValue(activeBidTotal, 'currency') }}
            </span>
          </h4>

          <h4>Deal summary list:</h4>

          <v-expansion-panels v-model="activePanels" multiple>
            <v-expansion-panel v-for="dealSummary in this.dealSummaryData" :key="dealSummary.id" class="mt-5 bordered">
              <v-expansion-panel-header :style="dealSummary.status === 'active'
                  ? 'color:green'
                  : 'color:gray'
                ">
                <span>
                  {{ dealSummary.deal.public_name }} - {{ dealSummary.city }} -
                  {{ dealSummary.province }} - LTV: {{ dealSummary.loan_to_value }}% - ({{
                    dealSummary.status
                  }})
                  <v-icon v-if="dealSummary.deal_summary_status == 'active'" class="ml-5" title="preview this deal"
                    @click="previewDeal(dealSummary.id)">
                    mdi-magnify
                  </v-icon>
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-data-table :headers="headers" :items="dealSummary.summary_offers" :items-per-page="5" @click:row="viewOffer(dealSummary.id, $event.id)
                    " dense>
                  <template v-slot:[`item.edit`]="{ item }">
                    <span style="color: green; cursor: pointer" v-if="dealSummary.status === 'active'" @click="edit_summary_offer_dialog_activate(
                          dealSummary.id,
                          item.id
                        )
                        ">
                      (edit)
                    </span>
                  </template>

                  <template v-slot:[`item.mortgage_term`]="{ item }">
                    {{ formatValue(item.mortgage_term, 'percentage') }}
                  </template>

                  <template v-slot:[`item.interest_rate`]="{ item }">
                    {{ formatValue(item.interest_rate, 'percentage') }}
                  </template>

                  <template v-slot:[`item.lender_fee`]="{ item }">
                    {{ formatValue(item.lender_fee, 'percentage') }}
                  </template>

                  <template v-slot:[`item.mortgage_amount`]="{ item }">
                    {{ formatValue(item.mortgage_amount, 'currency') }}
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row class="mt-10" justify="center">
        <v-col class="bordered" xs="12" xl="8">
          <h4 class="float-right">
            Won bid total:
            <span style="color: green" class="ml-2">
              {{ formatValue(wonBidTotal, 'currency') }}
            </span>
          </h4>

          <h4>Won bids list:</h4>

          <v-expansion-panels v-model="wonPanel" multiple>
            <v-expansion-panel v-for="dealSummary in this.wonDealSummaryData" :key="dealSummary.id" class="mt-5 bordered">
              <v-expansion-panel-header>
                {{ dealSummary.deal.public_name }} - {{ dealSummary.city }} -
                {{ dealSummary.deal.province }} - LTV: {{ dealSummary.loan_to_value }}%
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table :headers="headers" :items="dealSummary.summary_offers" :items-per-page="5" dense>
                  <!-- <v-data-table :headers="headers" :items="dealSummary.summary_offers" :items-per-page="5"
                 @click:row="viewOffer(dealSummary.deal_summary_id, $event.offer_id)"
                  dense> -->
                  <template v-slot:[`item.term`]="{ item }">
                    {{ formatValue(item.term, 'percentage') }}
                  </template>

                  <template v-slot:[`item.interest_rate`]="{ item }">
                    {{ formatValue(item.interest_rate, 'percentage') }}
                  </template>

                  <template v-slot:[`item.lender_fee`]="{ item }">
                    {{ formatValue(item.lender_fee, 'percentage') }}
                  </template>

                  <template v-slot:[`item.mortgage_amount`]="{ item }">
                    {{ formatValue(item.mortgage_amount, 'currency') }}
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <full-offer-details-dialog ref="fullOfferDetailsDialog" @close="temp_deal_summary_id = '';
          temp_offer_id = '';
          dialog = false;
                                                    " @edit=" edit_summary_offer_dialog_activate " />

          <Add_Deal_Summary_Offer :company=" company " :deal_summary_data=" edit_deal_summary_data "
            :dialog=" show_add_offer_dialog " :edit_summary_offer_data=" edit_summary_offer_data "
            :edit_summary_offer_dialog=" edit_summary_offer_dialog " view_type="logged_in" @close="
              show_add_offer_dialog = false;
              edit_summary_offer_data = {};
              edit_summary_offer_dialog = false;
              getData();
              $refs.fullOfferDetailsDialog.toggleViewOffer();
            " />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from '@/plugins/API';
import Add_Deal_Summary_Offer from '@/components/Add_Deal_Summary_Offer';
import FullOfferDetailsDialog from '@/components/FullOfferDetailsDialog';

export default {
  components: {
    Add_Deal_Summary_Offer,
    FullOfferDetailsDialog,
  },

  data: () => ({
    activePanels: [0],
    wonPanel: [0],
    componentLoading: true,
    isEmptyData: false,
    company: {},
    dealSummaryData: [],
    wonDealSummaryData: [],
    edit_summary_offer_data: {},
    edit_deal_summary_data: {},
    edit_summary_offer_dialog: false,
    show_add_offer_dialog: false,
    headers: [
      { text: '', value: 'edit' },
      { text: 'Term', value: 'mortgage_term' },
      { text: 'Interest rate', value: 'interest_rate' },
      { text: 'Lender fee', value: 'lender_fee' },
      { text: 'Amount', value: 'mortgage_amount' },
      { text: 'Comments', value: 'comments' },
      { text: 'Conditions', value: 'conditions' },
      { text: 'Status', value: 'status' },
    ],
  }),

  computed: {
    activeBidTotal() {

      let totalSum = 0;

      for (const summaryData of this.dealSummaryData) {
        let maxMortgageAmount = 0;

        for (const offer of summaryData.summary_offers) {
          const mortgageAmount = parseFloat(offer.mortgage_amount);
          if (mortgageAmount > maxMortgageAmount) {
            maxMortgageAmount = mortgageAmount;
          }
        }

        totalSum += maxMortgageAmount;
      }

      return totalSum;
    },

    wonBidTotal() {
      let totalSum = 0;

      for (const summaryData of this.wonDealSummaryData) {
        let maxMortgageAmount = 0;

        for (const offer of summaryData.summary_offers) {
          const mortgageAmount = parseFloat(offer.mortgage_amount);
          if (mortgageAmount > maxMortgageAmount) {
            maxMortgageAmount = mortgageAmount;
          }
        }

        totalSum += maxMortgageAmount;
      }

      return totalSum;
    },

   
  },

  methods: {
    previewDeal(value) {
      this.$router.push(`/dashboard/mortgagesAvailable/read/${value}`);
    },

    viewOffer(deal_summary_id, offer_id) {
      this.$refs.fullOfferDetailsDialog.toggleViewOffer();
      this.$refs.fullOfferDetailsDialog.getData(deal_summary_id, offer_id);
    },

    formatValue(value, format) {
      switch (format) {
        case 'currency':
          return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        case 'percentage':
          return value + '%';
        default:
          return value;
      }
    },

    async edit_summary_offer_dialog_activate(deal_summary_id, offer_id) {
      try {
        const res = await API().get(
          `/api/user_read_deal_summary/${deal_summary_id}/`
        );

        this.edit_deal_summary_data = res.data;

        const offer_data = res.data.summary_offers.find(
          (el) => el.id === offer_id
        );

        this.edit_summary_offer_data = offer_data;
        this.edit_summary_offer_dialog = true;
        this.show_add_offer_dialog = true;
      } catch (error) {
        console.log(error);
      }
    },

    async getData() {
      try {
        this.company = this.$store.getters['Auth/getAuthUser'].companies[0];

        const res = await API().get(
          `/api/user_deal_summaries_my_bids?user_id=${this.$store.state['Auth'].user.id}&company_id=${this.company.id}`
        );
        console.log(res.data);
        this.dealSummaryData = res.data.deal_summaries_with_my_active_bids;
        this.wonDealSummaryData = res.data.deal_summaries_my_won_bids;








        this.componentLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}
</style>
