<template>
  <v-dialog
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    v-model="dialog"
  >
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="deal_summary_data.status == 'active'"
        color="green darken-4"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Make a new offer
      </v-btn>
    </template> -->

    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title>Make an offer</v-toolbar-title>

        <v-spacer />
        <v-toolbar-items>
          <!-- <v-btn dark text @click="dialog = false"> Submit </v-btn> -->
        </v-toolbar-items>
      </v-toolbar>

      <v-container>
        <br />

        <p>
          Thank you for your interest in this lending opportunity. If your offer
          is short listed, you will be provided with the full application
          details for further evaluation.
        </p>

        <p>
          An offer made here is not binding; though, given the information
          provided in the summary will match application, your offer here should
          be true to your intentions on this file. An official mortgage
          commitment with conditions for borrower to meet must still be issued
          with your authorization and signature.
        </p>

        <p>
          <strong
            >You are able to make multiple offers on a lending opportunity,
            should you want to change your combination of mortgage
            amount/term/rates/conditions etc.
          </strong>
        </p>

        <br />

        <p>
          You are making an offer on behalf of
          <strong style="text-transform: capitalize">{{ company.name }}</strong>
        </p>

        <br />

        <v-form ref="lender_deal_offer_form" v-model="formRules.valid">
          <v-text-field
            outlined
            label="Mortgage Amount ($)"
            type="number"
            v-model="new_offer.mortgage_amount"
            :placeholder="deal_summary_data.amount_requested"
            :rules="formRules.required"
          />

          <p>Mortgage Position</p>

          <v-radio-group
            row
            v-model="new_offer.mortgage_position"
            :rules="formRules.required"
          >
            <v-radio label="1st" value="1" />

            <v-radio label="2nd" value="2" />

            <v-radio label="3rd" value="3" />

            <v-radio label="4th" value="4" />
          </v-radio-group>

          <v-text-field
            outlined
            label="Term (months)"
            placeholder="Term (months)"
            type="number"
            v-model="new_offer.mortgage_term"
            :rules="formRules.required"
          />

          <v-text-field
            outlined
            label="Interest Rate %"
            placeholder="Interest Rate %"
            type="number"
            v-model="new_offer.interest_rate"
            :rules="formRules.required"
          />

          <v-text-field
            outlined
            label="Lender Fee %"
            placeholder="Lender Fee %"
            type="number"
            v-model="new_offer.lender_fee"
            :rules="formRules.required"
          />

          <v-textarea
            outlined
            label="Comments"
            placeholder="Comments"
            v-model="new_offer.comments"
          />

          <p>
            You can choose to disable conditions. The less conditions in the
            offer, the more competitive it is to the borrower when choosing
            between options.
          </p>

          <v-switch
            label="Appraisal is required on this property"
            v-model="new_offer.appraisal"
          />

          <v-switch
            label="Insurance with lender named loss payable required on this property"
            v-model="new_offer.insurance"
          />

          <v-switch
            label="Credit check is required on borrower (Note, we can only send credit reports with applicaitons through Filogix)"
            v-model="new_offer.credit_check"
          />

          <v-switch
            label="Review of income documents required by lender"
            v-model="new_offer.income_verification"
          />

          <v-switch
            label="Review of personal income tax documents required by lender"
            v-model="new_offer.tax_review"
          />

          <v-switch
            label="Review of strata/estoppel/condo documents required by lender"
            v-model="new_offer.strata"
          />

          <v-alert
            v-show="errorMsg !== null"
            class="mt-5"
            type="error"
            title="Error"
            transition="fade-transition"
          >
            {{ errorMsg }}
          </v-alert>

          <v-btn
            color="green darken-4"
            v-if="!form_processing && !edit_summary_offer_dialog"
            @click="submit_offer"
          >
            Submit Offer
          </v-btn>

          <v-btn
            color="green darken-4"
            v-if="!form_processing && edit_summary_offer_dialog"
            @click="update_offer"
          >
            Update Offer
          </v-btn>

          &nbsp;

          <v-btn
            color="red"
            v-if="!offer_submit_success"
            :disabled="form_processing"
            @click="$emit('close')"
          >
            Cancel/Close
          </v-btn>

          <v-progress-circular
            class="ml-5"
            color="primary"
            v-if="!offer_submit_success && form_processing"
            indeterminate
          />

          <v-chip
            class="ma-2"
            color="green"
            text-color="white"
            v-if="offer_submit_success && errorMsg == null"
          >
            Offer recieved successfully!
          </v-chip>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import API from '@/plugins/API';
import filters from '@/plugins/filters';

export default {
  props: [
    'deal_summary_data',
    'company',
    'view_type',
    'summary_link_token',
    'dialog',
    'edit_summary_offer_data',
    'edit_summary_offer_dialog',
  ],

  data() {
    return {
      // dialog: false,

      errorMsg: null,
      offer_submit_success: false,
      form_processing: false,

      formRules: {
        valid: false,
        required: [(value) => !!value || 'This is a required field.'],
      },

      new_offer: {
        mortgage_amount: null,
        mortgage_position: null,
        mortgage_term: null,
        interest_rate: null,
        lender_fee: null,
        comments: null,
        appraisal: true,
        insurance: true,
        credit_check: false,
        income_verification: false,
        tax_review: false,
        strata: false,
        lender_decline: false, // upon adding offer lender_decline automatically set to false
      },
    };
  },
  methods: {
    async update_offer() {
      this.form_processing = true;
      this.$refs.lender_deal_offer_form.validate();

      if (this.formRules.valid) {
        // If it is a logged in user, should state which user
        // If it is a token, should be the user token information
        try {
          let offer_response;

          if (this.view_type == 'logged_in') {
            offer_response = await API().patch(
              '/api/user_update_summary_offer',
              {
                company_id: parseInt(this.company.id),
                offer: this.new_offer,
              }
            );
          }

          if (this.view_type == 'summary_link_token') {
            offer_response = await API().patch(
              `/api/summary_link_update_summary_offer?summary_link_token=${this.summary_link_token}`,
              {
                company_id: parseInt(this.company.id),
                offer: this.new_offer,
              }
            );
          }

          if (offer_response.status == 201) {
            // close the dialog
            this.offer_submit_success = true;
            this.$emit('getDealInfo');

            setTimeout(() => {
              this.offer_submit_success = false;
              this.$emit('close');
              // reset new offer form

              this.new_offer.mortgage_amount =
                this.deal_summary_data.amount_requested;

              this.new_offer.mortgage_term =
                this.deal_summary_data.proposed_term;

              this.new_offer.mortgage_position = String(
                this.deal_summary_data.mortgage_position
              );

              this.new_offer.interest_rate = null;
              this.new_offer.lender_fee = null;
              this.new_offer.comments = null;
              this.new_offer.appraisal = true;
              this.new_offer.insurance = true;
              this.new_offer.credit_check = false;
              this.new_offer.income_verification = false;
              this.new_offer.tax_review = false;

              this.$refs.lender_deal_offer_form.resetValidation();

              this.form_processing = false;
            }, 1000);
          }
        } catch (error) {
          this.errorMsg = error;
          console.log(error);
          setTimeout(() => {
            this.errorMsg = null;
            this.form_processing = false;
          }, 2000);
        }
      } else {
        this.form_processing = false;
      }
    },

    async submit_offer() {
      this.form_processing = true;
      this.$refs.lender_deal_offer_form.validate();

      if (this.formRules.valid) {
        // If it is a logged in user, should state which user
        // If it is a token, should be the user token information
        try {
          let offer_response;

          if (this.view_type == 'logged_in') {
            // let company = this.$store.getters["Auth/getAuthUser"].companies[0].id;
            offer_response = await API().post(
              '/api/create_deal_summary_offer',
              {
                deal_summary_id: parseInt(this.deal_summary_data.id),
                company_id: parseInt(this.company.id),
                offer: this.new_offer,
              }
            );
          }

          if (this.view_type == 'summary_link_token') {
            // let company =
            //   this.$store.getters["Auth/getAuthUser"].companies[0].id;
            offer_response = await API().post(
              `/api/summary_link_create_deal_summary_offer?summary_link_token=${this.summary_link_token}`,
              {
                deal_summary_id: parseInt(this.deal_summary_data.id),
                company_id: parseInt(this.company.id),
                offer: this.new_offer,
              }
            );
          }

          //   console.log(offer_response);

          if (offer_response.status == 201) {
            // close the dialog
            this.offer_submit_success = true;
            this.$emit('getDealInfo');
            // this.getDealInfo();

            setTimeout(() => {
              this.offer_submit_success = false;
              this.$emit('close');
              // reset new offer form

              this.new_offer.mortgage_amount =
                this.deal_summary_data.amount_requested;

              this.new_offer.mortgage_term =
                this.deal_summary_data.proposed_term;

              this.new_offer.mortgage_position = String(
                this.deal_summary_data.mortgage_position
              );

              this.new_offer.interest_rate = null;
              this.new_offer.lender_fee = null;
              this.new_offer.comments = null;
              this.new_offer.appraisal = true;
              this.new_offer.insurance = true;
              this.new_offer.credit_check = false;
              this.new_offer.income_verification = false;
              this.new_offer.tax_review = false;

              this.$refs.lender_deal_offer_form.resetValidation();

              this.form_processing = false;
            }, 1000);
          }
        } catch (error) {
          this.errorMsg = error;
          console.log(error);
          setTimeout(() => {
            this.errorMsg = null;
            this.form_processing = false;
          }, 2000);
        }
      } else {
        this.form_processing = false;
      }
    },
  },

  watch: {
    edit_summary_offer_dialog(active) {
      if (active) {
        this.new_offer = this.edit_summary_offer_data;

        this.new_offer.mortgage_position = String(
          this.edit_summary_offer_data.mortgage_position
        );
      } else {
        delete this.new_offer.id;
        this.new_offer.interest_rate = null;
        this.new_offer.lender_fee = null;
        this.new_offer.comments = null;
        this.new_offer.appraisal = true;
        this.new_offer.insurance = true;
        this.new_offer.credit_check = false;
        this.new_offer.income_verification = false;
        this.new_offer.tax_review = false;
      }
    },
  },

  mounted() {
    this.new_offer.mortgage_amount = this.deal_summary_data.amount_requested;
    this.new_offer.mortgage_term = this.deal_summary_data.proposed_term;
    this.new_offer.mortgage_position = String(
      this.deal_summary_data.mortgage_position
    );
  },

  filters: {
    ...filters,
  },
};
</script>
