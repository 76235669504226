module.exports = {
    dateFormat(value) {
        let d = new Date(value);
        return d.toLocaleDateString("en-US");
    },
    formatPrice(value) {
        // if (typeof value !== "number") {
        //   return value;
        // }
        var formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
        });
        return formatter.format(value);
    },

    booleanString(x) {
        if (x == true) {
            return "Yes";
        } else {
            return "No";
        }
    },
    capitalize(value) {
        return value.toUpperCase()
    },
    capitalize_first_letter(value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    },
    currency(value) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(parseInt(value));

    },
    mortgagePosition(value) {
        if (value == 1) {
            return "1st";
        }
        if (value == 2) {
            return "2nd";
        }
        if (value == 3) {
            return "3rd";
        }
        if (value == 4) {
            return "4th";
        }
        if (value == 5) {
            return "5th";
        }
    },
    yes_no(value) {
        if (value) {
            return 'Yes'
        } else {
            return 'No'
        }
    }
}